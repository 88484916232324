import React from "react";
import "./Slider.scss";

import Postor1 from "../../assets/img/postor1.png";
import Postor2 from "../../assets/img/postor2.png";
import Postor3 from "../../assets/img/postor3.png";
import Postor4 from "../../assets/img/postor4.png";

const Slider = () => {
  return (
    <div className="">
      <div className=" container mx-auto px-4">
        
        {/*Offer Zone*/}
        <section className="overflow-hidden text-gray-700 mt-4">
          <h2 className="text-center font-extrabold bg-clip-text text-[#495164] mb-4 text-[14px] leading-[17.57px] tracking-[0.04em] bg-[#495164] w-[296px] mx-auto md:text-[18px] md:leading-[21px] lg:text-[20px] lg:leading-[24px] md:w-[360px] lg:w-[400px]">
            OFFER ZONE
          </h2>
          <div className="container px-1 py-1 mx-auto lg:pt-12 lg:px-32">
            <div className="flex flex-wrap -m-1 md:-m-2">
              <div className="flex flex-wrap w-1/2">
                <div className="w-full p-1 md:p-2">
                  <img
                    alt=""
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src={Postor1}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="w-full p-1 md:p-2">
                  <img
                    alt=""
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src={Postor2}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="w-full p-1 md:p-2">
                  <img
                    alt=""
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src={Postor3}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="w-full p-1 md:p-2">
                  <img
                    alt=""
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src={Postor4}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Slider;
