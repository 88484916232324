import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./shop-horizontal-card.scss";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { IoPinSharp } from "react-icons/io5";

const ShopHorizontalCard = (props) => {
  const [image, setImage] = useState("");
  const [shopDetails, setShopDetails] = useState({
    shopName: "",
    shopCategory: "",
    rating: null,
    reviews: null,
    aboutUs: "",
    street: "",
    area: "",
  });

  useEffect(() => {
    if (props?.shop) {
      const images = JSON.parse(props?.shop?.images || "[]");
      if (images && images.length > 0) {
        setImage(images[0]);
      }

      setShopDetails({
        shopName: props.shop.shopName || "",
        shopCategory: props.shop.shopCategory || "",
        rating: props.shop.rating || null,
        reviews: props.shop.reviews || null,
        aboutUs: props.shop.aboutUs || "",
        area: props.shop.area || "",
        street: props.shop.street || "",
      });
    }
  }, [props.shop]);

  return (
    <div className="relative w-[300px] border border-gray-200 rounded-lg shadow-lg overflow-hidden bg-white">
      {/* Logo (Fixed at the top, covering the whole top part) */}
      <div className="w-full h-[200px] bg-gray-100">
        {image ? (
          <img
            src={image}
            alt="Shop"
            className="w-full h-full object-contain"
          />
        ) : (
          <div className="w-full h-full bg-gray-200"></div>
        )}
      </div>

      {/* Shop Details */}
      <div className="p-4 flex flex-col justify-between">
        {/* Shop Name and Category */}
        <div className="text-center justify-center">
          <h2 className="text-lg font-bold text-gray-900 truncate">
            {shopDetails.shopName}
          </h2>
          {shopDetails.shopCategory && (
            <span
              className={`inline-block mt-1 px-3 py-1 text-xs font-bold rounded-md ${
                shopDetails.shopCategory === "Uni-Sex"
                  ? "border border-[#8457f6] text-[#8457f6]"
                  : shopDetails.shopCategory === "Men"
                  ? "border border-blue-500 text-blue-500"
                  : shopDetails.shopCategory === "Women"
                  ? "border border-pink-500 text-pink-500"
                  : "bg-gray-200 text-gray-500"
              }`}
            >
              {shopDetails.shopCategory}
            </span>
          )}
        </div>

        {/* Rating */}
        {shopDetails.rating !== null && (
          <div className="flex items-center mt-2">
            <StarRateRoundedIcon style={{ color: "#FFB800" }} />
            <span className="text-gray-900 pl-1">
              {shopDetails.rating.toFixed(1)}
            </span>
            <span className="text-gray-500 pl-2 text-xs">
              ({shopDetails.reviews} Reviews)
            </span>
          </div>
        )}

        {/* About Us (Truncated if too long) */}

        <span className="text-gray-600 h-12 text-center  mt-2 text-sm line-clamp-3">
          {shopDetails.aboutUs}
        </span>

        {/* Address */}
        {(shopDetails.street || shopDetails.area) && (
          <div className="mt-2 h-12 flex items-center justify-center text-gray-500 text-sm">
            <IoPinSharp className="text-red-500 text-xl mr-1" />
            <span className="truncate">
              {shopDetails.street ? shopDetails.street : ""}
              {shopDetails.street && shopDetails.area ? ", " : ""}
              {shopDetails.area ? shopDetails.area : ""}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopHorizontalCard;
