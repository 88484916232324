import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Shops.scss";
import ShopHorizontalCard from "../../components/shop-horizontal-card/shop-horizontal-card";
import HomeService from "../../services/HomeService";

const Shops = () => {
  const [shopsList, setShopsList] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    getShopsList();
  }, []);

  const getShopsList = () => {
    HomeService.GetShopsList().then((res) => {
      let data = res["data"];
      setShopsList(data.shops);
    });
  };

  return (
    <div className="mt-3">
      <div className="text-center mb-6">
        <h1 className="text-base md:text-2xl font-extrabold ">
          Salons and Parlours Near You
        </h1>
      </div>
      <div className="p-0 md:p-6 bg-[#F2F2F2] mt-8">
        <div className="container mx-auto row">
          {shopsList.map((data, index) => {
            return (
              <div className="mt-3 col-sm-12 col-md-6 col-lg-4 " key={index}>
                <div
                  onClick={() => {
                    navigate(`/shop/${data?.shopName}?id=${data?.shopId}`);
                  }}
                  className="px-1 cursor-pointer"
                >
                  <ShopHorizontalCard shop={data} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Shops;
