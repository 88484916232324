import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { jwtDecode } from "jwt-decode";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import "./App.css";
import Home from "./pages/Home/Home";
import Shops from "./pages/Shops/Shops";
import Offers from "./pages/Offers/Offers";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import ShopDetails from "./pages/Shops/Details/Details";
import BookingPage from "./pages/Booking/History/History";
import ServicesShopsList from "./pages/Services/List/List";
import EditProfile from "./pages/EditProfile/EditProfile";
import About from "./pages/About";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Help from "./pages/Help";
import SearchResultsPage from "./pages/SearchResults/SearchResultsPage";
import ContactUs from "./pages/ContactUs";
import ServiceSearchResults from "./pages/SearchResults/ServiceSearchResults";
import ShopSearchResults from "./pages/SearchResults/ShopSearchResults";
import CompleteProfile from "./components/CompleteProfile/CompleteProfile";

function App() {

  const isTokenExpired = () => {
    const token = localStorage.getItem("token");
  
    if (!token) {
      // No token found
      return true;
    }
  
    try {
      // Decode the token to get the expiration time
      const decodedToken = jwtDecode(token);
      
      // Check if the current time is greater than the expiration time
      const currentTime = Date.now() / 1000; // Current time in seconds
      if (decodedToken.exp && decodedToken.exp < currentTime) {
        // Token has expired
        return true;
      }
  
      // Token is still valid
      return false;
    } catch (error) {
      // Error decoding token, assume it's invalid
      console.error("Error decoding token:", error);
      return true;
    }
  };

  if (isTokenExpired() && localStorage.getItem("token")) {
    localStorage.removeItem("token");
    window.location.reload();
  }

  

  return (
    <React.Fragment>
      <div className="font-Mulish">
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="shops" element={<Shops />} />
            <Route path="offers" element={<Offers />} />
            <Route path="booking-history" element={<BookingPage />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            {/*<Route path="login" element={<Login />} />*/}
            <Route path="signup" element={<Signup />} />
            <Route path="shop/:shopName" element={<ShopDetails />} />
            <Route path="service" element={<ServicesShopsList />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/help" element={<Help />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/search-results" element={<SearchResultsPage />} />
            <Route path="/service-search-results" element={<ServiceSearchResults />} />
            <Route path="/shop-search-results" element={<ShopSearchResults />} />
            <Route path="/complete-profile" element={<CompleteProfile />} />
          </Routes>
          <Toaster position="top-center" reverseOrder={false} />
          <Footer
            facebookUrl="https://www.facebook.com/groomfully.groomfully"
            instagramUrl="https://www.instagram.com/groomsy.in"
          />
        </Router>
      </div>
    </React.Fragment>
  );
}

export default App;
