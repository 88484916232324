import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import BookingModal from "../../../components/Modal/BookingModal";
import "./Details.scss";
import HomeService from "../../../services/HomeService";
import HeroSearchBar from "../../../components/Search/HeroSearchBar";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import RatingSummary from "../../../components/RatingSummary/RatingSummary";
import ReviewModal from "../../../components/Modal/ReviewModal";
import CustomerReviews from "../../../components/CustomerReviews/CustomerReviews";
import { Helmet } from "react-helmet";

const ShopDetails = (props) => {
  let params = useParams();
  let deviceType = "desktop";

  const [modalShow, setModalShow] = useState(false);
  const [shopDetail, setShopDetail] = useState();
  const [profilePic, setProfilePic] = useState("");
  const [reviewModalShow, setReviewModalShow] = useState(false);
  const [serviceDetail, setServiceDetail] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentDay, setCurrentDay] = useState(null);

  const { shopName } = useParams(); // Extract shopName and id from the URL
  const [searchParams] = useSearchParams();

  const Ratings = [];
  const offers = [];
  const workingHours = [];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const shopId = searchParams.get("id");

  useEffect(() => {
    const today = new Date();
    const istTime = today.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    const istDate = new Date(istTime);
    const dayIndex = istDate.getDay();
    setCurrentDay(dayIndex);

    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getShopDetails(shopId);
  }, [shopId]);

  const openMap = () => {
    window.open("https://maps.google.com?q=" + shopDetail.latLong);
  };

  const getShopDetails = (id) => {
    HomeService.GetShopDetails(id)
      .then((response) => {
        try {
          const data = response?.data?.shopDetail || {};

          if (data.images) {
            data.images = JSON.parse(data.images) || [];
          }

          setShopDetail({
            shopName: data.shopName || "",
            shopCategory: data.shopCategory || "",
            services: data.services || [],
            rating: data.rating || 0,
            reviews: data.reviews || 0,
            street: data.street || "",
            area: data.area || "",
            address: data.address || "",
            city: data.city || "",
            state: data.state || "",
            pincode: data.pincode || "",
            landmark: data.landmark || "",
            images: data.images || [],
            aboutUs: data.aboutUs || "",
          });
        } catch (error) {
          console.error("Error parsing JSON data:", error);
          setShopDetail({});
        }
      })
      .catch((error) => {
        console.error("Error fetching shop details:", error);
        setShopDetail({});
      });
  };

  const ArticleCard = ({ image, index }) => {
    return (
      <div className="relative w-full overflow-hidden pb-[75%] md:pb-[50%] lg:pb-[40%] max-h-[500px] lg:max-h-[600px]">
        {image.includes(".mp4") ? (
          <video
            className="absolute top-0 left-0 w-full h-full object-contain md:object-cover lg:object-contain"
            controls
            key={index}
            src={image}
            type="video/mp4"
            autoPlay
            loop
            muted
          />
        ) : (
          <img
            className="absolute top-0 left-0 w-full h-full object-contain md:object-cover lg:object-contain"
            src={image}
            alt={`Slide ${index}`}
            key={index}
          />
        )}
      </div>
    );
  };

  const CustomDot = ({ shopDetail, onClick, active, index }) => {
    const image = shopDetail.images[index];

    return (
      <button
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        className={classNames(
          "h-12 w-12 rounded overflow-hidden border-2 border-transparent",
          {
            "border-blue-500": active,
          }
        )}
        key={index}
      >
        {image.includes(".mp4") ? (
          <video
            className="h-full w-full object-cover"
            src={image}
            muted
            playsInline
            preload="metadata"
          />
        ) : (
          <img
            className="h-full w-full object-cover"
            src={image}
            alt="Thumbnail"
          />
        )}
      </button>
    );
  };

  const handleWriteReviewClick = () => {
    if (isLoggedIn) {
      setReviewModalShow(true);
    } else {
      alert("Please log in to write a review.");
    }
  };

  // const images = images.map((image, index) => <img key={index} src={image} />);

  return (
    <div>
      <Helmet>
        <title>{`Book ${shopDetail?.shopName} in ${shopDetail?.city} | Groomsy`}</title>
        <meta
          name="description"
          content={`Book an appointment at ${
            shopDetail?.shopName
          }, a top-rated ${shopDetail?.shopCategory} salon in ${
            shopDetail?.city
          }. Offering services like ${shopDetail?.services
            ?.map((service) => service.serviceName)
            .join(", ")} with a rating of ${shopDetail?.rating}.`}
        />
      </Helmet>
      <HeroSearchBar />

      {/* Main content */}
{shopDetail && (
  <div className="flex flex-col md:flex-row mt-4 p-6">
    {/* Left Side - Carousel and Shop Details */}
    <div className="w-full md:w-2/3 relative pr-4 mr-4"> {/* Added 'mr-4' here */}
      {/* Carousel Section */}
      <Carousel
        showDots
        deviceType={deviceType}
        slidesToSlide={1}
        containerClass="carousel-with-custom-dots"
        responsive={responsive}
        partialVisible
        infinite
        customDot={<CustomDot shopDetail={shopDetail} />}
      >
        {shopDetail?.images?.map((image, index) => (
          <ArticleCard key={index} image={image} />
        ))}
      </Carousel>

      {/* Rating Overlay on Carousel */}
      <div className="absolute top-0 right-0 p-4 bg-black bg-opacity-50 text-white">
        <span className="text-2xl font-bold">
          {shopDetail?.rating || "N/A"}
        </span>
        <div className="text-sm">{shopDetail?.reviews} Reviews</div>
      </div>

      {/* Shop Name, Category and About Us */}
      <div className="px-4 py-4">
        <h1 className="text-2xl text-[#2A2A2A] font-extrabold">
          {shopDetail?.shopName}
        </h1>

        <span
          className={`ml-2 px-2 py-1 rounded-md text-xs font-bold ${
            shopDetail.shopCategory === "Uni-Sex"
              ? "!border border-[#8457f6] bg-clip-text text-transparent"
              : shopDetail.shopCategory === "Men"
              ? "!border !border-blue-500 text-blue-500"
              : shopDetail.shopCategory === "Women"
              ? "!border !border-pink-500 text-pink-500"
              : "bg-gray-200 text-gray-500"
          }`}
          style={
            shopDetail.shopCategory === "Uni-Sex"
              ? {
                  background:
                    "linear-gradient(180deg, #0057FF 0%, #FF57EE 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }
              : {}
          }
        >
          {shopDetail?.shopCategory}
        </span>

        <div className="font-semibold text-[#6B6B6B] flex flex-wrap space-x-2">
          <h6>{shopDetail?.address}</h6>
          <span>,</span>
          <h6>{shopDetail?.street}</h6>
          <span>,</span>
          <h6>{shopDetail?.area}</h6>
          <span>,</span>
          <h6>{shopDetail?.city}</h6>
          <span>,</span>
          <h6>
            {shopDetail?.state} - {shopDetail?.pincode}
          </h6>
          {shopDetail?.landmark && (
            <>
              <span>,</span>
              <h6>Landmark: {shopDetail?.landmark}</h6>
            </>
          )}
        </div>
      </div>
    </div>

    {/* Right-Side Content */}
    <div className="w-full md:w-1/3 p-4 bg-gray-100">
      {/* Map */}
      <div className="p-4 shadow-md rounded-xl">
        <button
          onClick={openMap}
          className="rounded-lg mt-1 w-full text-white bg-[#1E85FF] p-2 text-lg"
        >
          View Map →
        </button>
        <div className="text-center text-sm mt-2">{shopDetail?.address}</div>
      </div>

      {/* About Us */}
      <div className="mt-4">
        <h4 className="text-xl font-bold">About Us</h4>
        <p className="text-gray-600">
          {shopDetail?.aboutUs || "No description available."}
        </p>
      </div>

      {/* Working Hours Section */}
      <div className="mt-4">
        <h4 className="text-[#2A2A2A] font-extrabold text-xl">Working Hours</h4>
        <ul className="mt-2 p-0">
          {workingHours.map((day, index) => (
            <li
              key={index}
              className={`flex justify-between py-1 ${
                index === currentDay ? "font-bold text-black" : "text-gray-600"
              }`}
            >
              <span>{day.day}</span>
              <span>{day.hours}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)}

      {/* Services Section */}
      <div className="px-3 py-4 bg-[#F2F2F2] w-full mt-4">
        <h4 className="text-[#2A2A2A] mt-3 font-extrabold text-xl">
          Our Services
        </h4>
        <div className="mt-4 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
          {shopDetail?.services?.map((service, index) => (
            <div
              key={index}
              className="text-[#2A2A2A] bg-white p-4 rounded-lg shadow-md flex flex-col justify-between"
            >
              <span className="font-bold capitalize">
                {service?.serviceName}
              </span>
              <div className="flex flex-col mt-2">
                <span className="text-[#9D9D9D] text-md">Fare:</span>
                <div className="flex justify-between">
                  <span className="text-[#00BD13] font-bold text-lg">
                    ₹ {service?.discountPrice}
                  </span>
                  <span className="text-[#9D9D9D] line-through">
                    ₹ {service?.originalPrice}
                  </span>
                </div>
              </div>
              <button
                className="rounded-lg mt-4 bg-[#438179] font-bold p-2 text-[#2A2A2A] bg-opacity-10 border border-gray-300"
                onClick={() => {
                  if (isLoggedIn) {
                    setModalShow(true);
                    setServiceDetail(service);
                  } else {
                    alert("Please log in to book an appointment.");
                  }
                }}
              >
                Book
              </button>
            </div>
          ))}
        </div>

        {/* Rating Summary */}
        <RatingSummary ratings={Ratings} />

        {/* Reviews Section */}
        <div className="mt-6">
          <div className="flex flex-col items-center bg-white shadow-md p-4">
            <img
              src={profilePic || "https://via.placeholder.com/50"}
              alt="Profile"
              className="w-12 h-12 rounded-full mb-4"
            />
            <h3 className="text-lg font-bold">Rate and Review</h3>
            <p className="text-sm text-gray-500 mb-4">
              Share your experience with us
            </p>
            <button
              onClick={handleWriteReviewClick}
              className="bg-yellow-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-yellow-600"
            >
              Write a Review
            </button>
          </div>
        </div>

        {/* Customer Reviews */}
        <CustomerReviews
          reviewsData={
            Array.isArray(shopDetail?.reviews) ? shopDetail.reviews : []
          }
        />
      </div>

      {/* Modals */}
      <BookingModal
        data={serviceDetail}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ReviewModal
        show={reviewModalShow}
        onHide={() => setReviewModalShow(false)}
        shopName={shopDetail?.shopName}
        shopLocation={`${shopDetail?.city}, ${shopDetail?.state}`}
      />
    </div>
  );
};

export default ShopDetails;
