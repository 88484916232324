import React from "react";

const About = () => {
  return (
    <div className="container mx-auto py-6 px-4">
      <h1 className="text-3xl font-bold mb-4">About Us</h1>
      <p className="text-gray-700 leading-relaxed">
        Welcome to our company! We are passionate about providing the best
        services to our customers. Our mission is to...
      </p>
      {/* Add more content as needed */}
    </div>
  );
};

export default About;
