import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

const OtpModal = ({ isOpen, onClose, onSubmit, mobileNumber, resendOtp }) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [resendDisabled, setResendDisabled] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (value, index) => {
    const digit = value.replace(/[^0-9]/g, ""); 
    if (digit) {
      const newOtp = [...otp];
      newOtp[index] = digit;
      setOtp(newOtp);
      if (index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index]) {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const handleSubmit = () => {
    const otpValue = otp.join("");
    if (otpValue.length === 6) {
      setError("");
      onSubmit(otpValue); 
    } else {
      setError("Please enter the complete 6-digit OTP.");
    }
  };

  const handleResendOtp = () => {
    setResendDisabled(true);
    resendOtp();

    setTimeout(() => {
      setResendDisabled(false);
    }, 30000); 
  };

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-full">OTP Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex flex-col items-center">
        <p className="text-gray-700">We have sent a verification code to:</p>
        <p className="text-blue-600 font-semibold">{mobileNumber}</p>

        {/* OTP input fields */}
        <div className="flex justify-center mt-4 space-x-2">
          {[...Array(6)].map((_, index) => (
            <input
              key={index}
              id={`otp-input-${index}`}
              type="text"
              inputMode="numeric"
              maxLength="1"
              value={otp[index] || ""}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="w-12 h-12 text-xl text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label={`OTP Digit ${index + 1}`} 
            />
          ))}
        </div>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </Modal.Body>
      <Modal.Footer className="flex justify-between items-center">
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={otp.join("").length < 6} 
        >
          Verify OTP
        </Button>
      </Modal.Footer>
      <div className="text-center mt-3">
        <p className="text-gray-600">
          Didn’t receive your code?{" "}
          <span
            className={`text-blue-600 cursor-pointer ${
              resendDisabled ? "opacity-50" : ""
            }`}
            onClick={!resendDisabled ? handleResendOtp : null}
          >
            {resendDisabled ? "Resend OTP in 30s" : "Resend OTP"}
          </span>
        </p>
      </div>
    </Modal>
  );
};

export default OtpModal;
