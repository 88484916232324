import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BiSearch, BiX } from "react-icons/bi";

import Slider1 from "../../assets/img/slider1.png";
import Slider2 from "../../assets/img/slider2.png";
import Slider3 from "../../assets/img/slider3.png";

const HeroSearchBar = () => {
  const [location, setLocation] = useState("");
  const [query, setQuery] = useState("");
  const [time, setTime] = useState("Anytime");
  const [shops, setShops] = useState([]);
  const [services, setServices] = useState([]);
  const [textIndex, setTextIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const ws = useRef(null); 
  const slides = [Slider1, Slider2, Slider3];

  // Text suggestions
  const texts = [
    "Book your next Manicure",
    "Book your next Haircut",
    "Book your next Facial",
    "Book your next MakeUp",
    "Book your next Massage",
    "Book your next HairColouring",
  ];

 
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 2000);

    const textInterval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => {
      clearInterval(slideInterval);
      clearInterval(textInterval);
    };
  }, [slides.length, texts.length]);

  
  useEffect(() => {
    const connectWebSocket = () => {
      ws.current = new WebSocket("wss://api.groomsy.in");

      ws.current.onopen = () => {
        // WebSocket connected
      };

      ws.current.onclose = () => {
        
        setTimeout(connectWebSocket, 3000); 
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket error: ", error);
      };

      ws.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data && data.salons && data.services) {
            setShops(data.salons.slice(0, 5));
            setServices(data.services.slice(0, 5));
          }
        } catch (err) {
          console.error("Failed to parse WebSocket message: ", err);
        }
      };
    };

    connectWebSocket(); 

    return () => {
      if (ws.current) {
        ws.current.close(); 
      }
    };
  }, []);

  
  const handleSearch = () => {
    if (!query.trim() && !location.trim()) return; 

    const selectedService = services.find(
      (service) => service.serviceName === query
    );
    const selectedShop = shops.find((shop) => shop.shopName === query);

    if (selectedService) {
      navigate(`/service-search-results?query=${query}&time=${time}`);
    } else if (selectedShop) {
      navigate(`/shop-search-results?query=${query}&time=${time}`);
    } else {
      navigate(
        `/search-results?location=${location}&query=${query}&time=${time}`
      );
    }
  };

  
  const handleQueryChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (!value) {
      setShops([]);
      setServices([]);
    }

    
    if (
      ws.current &&
      ws.current.readyState === WebSocket.OPEN &&
      value.trim()
    ) {
      ws.current.send(value);
    }
  };

  return (
    <div className="relative">
      {/* Hero Banner */}
      <div className="absolute inset-0 z-0">
        <img
          src={slides[currentSlide]}
          alt={`Slide ${currentSlide}`}
          className="w-full h-[280px] md:h-[400px] object-cover opacity-60"
        />
      </div>

      {/* Text and Search Bar */}
      <div className="relative z-10 flex flex-col justify-center items-center h-[280px] md:h-[400px]">
        <h1 className="text-black text-4xl pt-4 font-bold mb-6 hidden sm:block">
          {texts[textIndex]}
        </h1>

        {/* Desktop Mode Search Bar */}
        <div className="hidden sm:flex items-center w-full max-w-5xl mx-auto px-4 md:px-8 lg:px-12 cursor-pointer relative">
  <div className="relative w-full flex items-center">
    <BiSearch className="absolute left-4 text-gray-400 text-xl" aria-label="Search Icon" />

    <input
      type="text"
      value={query}
      onChange={handleQueryChange}
      placeholder="Search services or businesses"
      className="w-full pl-12 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
    />

    {query && (
      <button
        onClick={() => {
          setQuery(""); 
          setServices([]);
          setShops([]);
        }}
        className="absolute right-10 text-gray-400"
        aria-label="Clear Search"
      >
        <BiX className="text-xl" />
      </button>
    )}

    <button
      onClick={handleSearch}
      className="absolute right-4 bg-transparent text-gray-400"
      aria-label="Submit Search"
    >
      <BiSearch className="text-xl" />
    </button>

    {/* Suggestions */}
    {(shops.length > 0 || services.length > 0) && (
      <div className="absolute top-full  w-full bg-white flex justify-between  border border-gray-200 rounded-lg z-50">
        <div className="w-1/2 p-4">
          <h3 className="text-lg font-semibold">Services</h3>
          <ul className="mt-2 p-0">
            {services.map((service, index) => (
              <li
                key={index}
                onClick={() =>
                  navigate(`/service?name=${service.serviceName}`)
                }
                className="cursor-pointer  py-2 hover:bg-gray-100"
              >
                {service.serviceName}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-1/2 p-4 ">
          <h3 className="text-lg font-semibold">Shops</h3>
          <ul className="mt-2 p-0">
            {shops.map((shop, index) => (
              <li
                key={index}
                onClick={() =>
                  navigate(`/shop/${shop.shopName}?id=${shop.shopId}`)
                }
                className="cursor-pointer  py-2 hover:bg-gray-100"
              >
                {shop.shopName}
              </li>
            ))}
          </ul>
        </div>
      </div>
    )}
  </div>
</div>


        {/* Mobile Mode Search Bar */}
        <div className="sm:hidden relative w-full">
          <div className="flex items-center w-full h-[44px] bg-[#F2F2F2] border-[1px] border-[#DDDDDD] rounded-lg shadow-lg cursor-pointer justify-between">
            <input
              type="text"
              value={query}
              onChange={handleQueryChange}
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
              placeholder="Search"
              className="w-full h-[18px] p-2 bg-[#F2F2F2] border-none focus:outline-none font-[600] text-[14px] leading-[17.57px] text-left"
              style={{ fontFamily: "Mulish" }}
            />
            {query && (
              <button
                onClick={() => {
                  setQuery(""); 
                  setServices([]);
                  setShops([]);
                }}
                aria-label="Clear Search"
              >
                <BiX className="w-5 h-5 text-gray-500 mr-2" />
              </button>
            )}
            <button onClick={handleSearch} aria-label="Submit Search">
              <BiSearch className="w-5 h-5 text-gray-500 mr-2" />
            </button>
          </div>

          {/* Suggestions for Mobile */}
          {(shops.length > 0 || services.length > 0) && (
            <div className="absolute top-full w-full bg-white border border-gray-300 shadow-lg rounded-b-lg z-50">
              <div className="p-2">
                {services.length > 0 && (
                  <div>
                    <h3 className="text-lg font-semibold mb-1">Services</h3>
                    <ul className="space-y-1 p-0">
                      {services.map((service, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            navigate(`/service?name=${service.serviceName}`)
                          }
                          className="cursor-pointer border-b border-gray-200 p-1 hover:bg-gray-100"
                        >
                          {service.serviceName}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {shops.length > 0 && (
                  <div className={services.length > 0 ? "mt-2" : ""}>
                    <h3 className="text-lg font-semibold mb-1">Shops</h3>
                    <ul className="space-y-1 p-0">
                      {shops.map((shop, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            navigate(`/shop/${shop.shopName}?id=${shop.shopId}`)
                          }
                          className="cursor-pointer  border-b border-gray-200 p-1 hover:bg-gray-100"
                        >
                          {shop.shopName}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroSearchBar;
