import axios from 'axios';
import './config';
import httpService from './HttpService';


const GetShopsList = () => {
    
    return httpService.get("users/shops")
}

const GetShopDetails = (id) => {
    return httpService.get("users/shop/detail/" + id);
}

const GetServiceShopsList = (name) => {
    return httpService.get("users/service/" + name);
}

const bookAppointment = (data) => {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'authorization': "Bearer " + localStorage.getItem('token')
        }
    };
    return httpService.post('users/appointment', data, requestOptions);
}

export default {
    GetShopsList,
    GetShopDetails,
    bookAppointment,
    GetServiceShopsList
}