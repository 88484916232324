import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import AuthService from "../../services/AuthService";
import OtpModal from "./OtpModal";
import logo from "../../assets/groomsy-logo.png";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast"; 
import { useNavigate } from "react-router-dom";

function LoginModal(props) {
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [inputError, setInputError] = useState("");  
  const [isRequestingOtp, setIsRequestingOtp] = useState(false);
  const navigate = useNavigate();

  const validate = (mobile) => {
    if (!mobile) {
      return "Mobile number is required";
    } else if (mobile.length !== 10 || isNaN(mobile)) {
      return "Mobile number should be 10 digits";
    }
    return "";
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const mobile = e.target.phone.value;
    const validationError = validate(mobile);

    if (validationError) {
      setInputError(validationError);
      return;
    }

    setInputError("");  
    setIsRequestingOtp(true);

    AuthService.requestOtp(mobile).then(
      () => {
        setMobileNumber(mobile);
        setOtpModalOpen(true);
        toast.success("OTP sent successfully!");
      },
      (errors) => {
        toast.error("Failed to send OTP. Please try again.");
      }
    ).finally(() => {
      setIsRequestingOtp(false);
    });
  };

  const closeOtpModal = () => setOtpModalOpen(false);

  const submitOtp = (otp) => {
    AuthService.verifyOtp(mobileNumber, otp)
      .then((response) => {
        localStorage.setItem("token", response["data"].token);
        closeOtpModal();
        props.onHide();
        toast.success("OTP verified! Login successful.");

        if (response.data.isNewUser) {
          navigate("/complete-profile", { state: { mobileNumber } });
          return;
        }
        window.location.reload();
      })
      .catch(() => {
        toast.error("Error verifying OTP. Please try again.");
      });
  };

  return (
    <>
      <Modal {...props} size="md" centered>
        <Modal.Body className="p-0 flex justify-center items-center">
          <div className="w-full bg-white p-8 rounded-lg shadow-lg relative">
            <button
              onClick={props.onHide}
              className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              <IoClose className="h-8 w-8 p-1 bg-gray-200 rounded-full" />
            </button>
            <div className="flex flex-col items-center">
              <img src={logo} alt="Groomsy Logo" className="w-32 mb-4" />
              <h2 className="text-2xl font-bold">Welcome,</h2>
              <p className="text-gray-600 text-center mb-6">Glad to see you!</p>

              <form onSubmit={onSubmit}>
                <input
                  name="phone"
                  placeholder="Mobile Number"
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  maxLength="10"
                />
                {inputError && (
                  <p className="text-red-500 text-sm mt-2">{inputError}</p>
                )}
                <button
                  type="submit"
                  className="mt-4 w-full bg-blue-600 text-white py-2 rounded-md"
                  disabled={isRequestingOtp}  
                >
                  {isRequestingOtp ? "Requesting OTP..." : "Request OTP"}
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* OTP Modal */}
      <OtpModal
        isOpen={otpModalOpen}
        onClose={closeOtpModal}
        mobileNumber={mobileNumber} 
        onSubmit={submitOtp}
        resendOtp={() => {
          AuthService.requestOtp(mobileNumber)
            .then(() => {
              toast.success("OTP resent successfully!");
            })
            .catch(() => {
              toast.error("Failed to resend OTP. Please try again.");
            });
        }}
      />
    </>
  );
}

export default LoginModal;
