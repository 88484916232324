import React from "react";

const ContactUs = () => {
  return (
    <div className="container mx-auto py-6 px-4">
      <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
      <p className="text-gray-700 leading-relaxed">
        Contact Us
      </p>
      {/* Add more content as needed */}
    </div>
  );
};

export default ContactUs;