import React from "react";

const Privacy = () => {
  return (
    <div className="container mx-auto py-6 px-4">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-gray-700 leading-relaxed">
        We take your privacy seriously. This privacy policy explains how we use
        and protect your information...
      </p>
      {/* Add more content as needed */}
    </div>
  );
};

export default Privacy;
