import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./List.scss";
import ServiceShop from "../../../components/service-shops/service-shops";
import HomeService from "../../../services/HomeService";

const ServicesShopsList = () => {
  const [shopsList, setShopsList] = useState([]);
  const [filteredShopsList, setFilteredShopsList] = useState([]);
  const [gender, setGender] = useState("All");
  const [sortBy, setSortBy] = useState("Ratings");
  const [serviceName, setServiceName] = useState("");
  const [isLoading, setIsLoading] = useState(true);  // Add isLoading state

  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");

  useEffect(() => {
    getShopsList(name);
    setServiceName(name);
  }, [name]);

  useEffect(() => {
    applyFilters();
  }, [shopsList, gender, sortBy]);

  const getShopsList = async (name) => {
    try {
      const res = await HomeService.GetServiceShopsList(name);
      const data = res["data"];
      setShopsList(data.services);
      setFilteredShopsList(data.services);
    } catch (error) {
      console.error("Failed to fetch shops list", error);
    } finally {
      setIsLoading(false);  // Set loading to false when data fetch is done
    }
  };

  const applyFilters = () => {
    let filtered = shopsList;

    // Apply gender filter
    if (gender !== "All") {
      filtered = filtered.filter(shop => shop.shopCategory === gender);
    }

    // Apply sorting
    if (sortBy === "Ratings") {
      filtered = filtered.sort((a, b) => b.rating - a.rating);
    } else if (sortBy === "Price Low - High") {
      filtered = filtered.sort((a, b) => a.discountPrice - b.discountPrice);
    } else if (sortBy === "Price High - Low") {
      filtered = filtered.sort((a, b) => b.discountPrice - a.discountPrice);
    }

    setFilteredShopsList([...filtered]);
  };

  return (
    <div className="container mx-auto my-3 px-4">
      <div className="max-w-4xl mx-auto mb-6">
        <h2 className="text-2xl font-semibold text-center">Shops For {name} Services</h2>
        <p className="text-center text-gray-500 mb-4">{filteredShopsList.length} Shops Found</p>
        
        {/* Filter Section */}
        <div className="flex justify-between items-center mb-6">
          <div className="flex-1">
            <label htmlFor="gender-select" className="block text-lg text-gray-600">Gender</label>
            <select
              id="gender-select"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="form-select mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="All">All</option>
              <option value="Men">Men</option>
              <option value="Women">Women</option>
              <option value="Uni-Sex">Uni-Sex</option>
            </select>
          </div>
          <div className="flex-1 ml-2">
            <label htmlFor="sortby-select" className="block text-lg text-gray-600">Sort By</label>
            <select
              id="sortby-select"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="form-select mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="Ratings">Ratings</option>
              <option value="Near By">Near By</option>
              <option value="Price Low - High">Price Low - High</option>
              <option value="Price High - Low">Price High - Low</option>
            </select>
          </div>
        </div>
      </div>

      {/* Display shops, loading spinner, or no service message */}
      {isLoading ? (
        <div className="flex justify-center items-center h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : filteredShopsList.length > 0 ? (
        <div className="row">
          {filteredShopsList.map((shop, index) => (
            <div className="mt-3 col-sm-12 col-md-4 col-lg-4" key={index}>
              <ServiceShop shop={shop} />
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-600 mt-6">
          <p>No service found</p>
        </div>
      )}
    </div>
  );
};

export default ServicesShopsList;
