import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginModal from "../Modal/LoginModal";
import LogoImage from "../../assets/logo/logo_resized.png";
import HeaderView from "./HeaderView";
import MenuOverlay from "./MenuOverlay";

import AuthService from "../../services/AuthService";

import { Typography } from "@material-tailwind/react";

const Header = () => {
  const [show, setShow] = useState(false);

  let navigate = useNavigate();

  const onLoginClick = () => {
    setShow(true);
  };

  const [modalShow, setModalShow] = useState(false);

  const [isTokenAvailable, setIsTokenAvailable] = useState(false);

  const [navbarOpen, setNavbarOpen] = useState(false);

  const [userDetails, setUserDetails] = useState({ userName: "User" });

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
    let token = JSON.parse(JSON.stringify(localStorage.getItem("token")));
    setIsTokenAvailable(!!token);

    if (token) {
      AuthService.getUserDetails()
        .then((response) => {
          if (response.data && response.data.user) {
            setUserDetails({ userName: response.data.user.userName });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const logout = () => {
    AuthService.logout();
    setIsTokenAvailable(false);
    setUserDetails([{ name: "User" }]);
    navigate("/");
    window.location.reload();
  };

  const navigateHome = () => {
    navigate("/");
  };

  const handleProfileClick = () => {
    navigate("/edit-profile"); // Navigate to the Edit Profile page
  };

  const [openNav, setOpenNav] = useState(false);

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <a href="#" className="flex items-center">
          Pages
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <a href="#" className="flex items-center">
          Account
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <a href="#" className="flex items-center">
          Blocks
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <a href="#" className="flex items-center">
          Docs
        </a>
      </Typography>
    </ul>
  );

  return (
    <header className="bg-white shadow-md">
      <nav className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div
          onClick={navigateHome}
          className="flex items-center cursor-pointer"
        >
          <img
            src={LogoImage}
            alt="Logo"
            className="h-[34px] top-[11px] left-[16px]"
          />
        </div>

        {/* Hamburger Menu Button */}
        <HeaderView navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />

        {/* Navigation Links for larger screens */}
        <div className="hidden lg:flex items-center space-x-4">
          {/* Conditionally render the Appointments link */}
          {isTokenAvailable && (
            <Link
              to="/booking-history"
              className="text-gray-800 text-decoration-none text-sm hover:text-gray-600"
            >
              Appointments
            </Link>
          )}

          {isTokenAvailable ? (
            <>
              <span
                onClick={handleProfileClick}
                className="cursor-pointer text-gray-800 text-sm hover:text-gray-600 whitespace-nowrap"
              >
                Welcome, {userDetails.userName}
              </span>
              <button
                onClick={logout}
                className="text-gray-800 text-sm hover:text-gray-600"
              >
                Logout
              </button>
            </>
          ) : (
            <button
              onClick={() => setModalShow(true)}
              className="text-gray-800 text-sm hover:text-gray-600"
            >
              Login
            </button>
          )}
        </div>
      </nav>

      {/* Mobile Menu Overlay */}
      <MenuOverlay
        navbarOpen={navbarOpen}
        setNavbarOpen={setNavbarOpen}
        isTokenAvailable={isTokenAvailable}
      />

      {/* Login Modal */}
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
    </header>
  );
};

export default Header;

/*

<Navbar className="bg-blue-600" collapseOnSelect expand="lg">
        <Container>
           <Navbar.Brand href="/">
            <img src={LogoImage} width="100px" alt="Groomsy" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" /> 

           <Navbar.Collapse
            className="justify-end groomsy-header"
            id="responsive-navbar-nav"
          >
        

            <label class="relative flex border border-slate-300 rounded-md w-75 bg-white">
             
              <input
                class="left-10 h-[60px] placeholder:italic placeholder:text-slate-400 block bg-white py-2 pl-9 pr-3 w-full focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                placeholder="Search shops"
                type="text"
                name="search"
              />
              <span class="absolute inset-y-0 px-3 right-0 flex items-center pl-2 bg-white">
                <AiOutlineSearch className="h-[25px] w-[25px]" />
              </span>
            </label>

            {!isTokenAvailable && (
              <Nav>
                <Nav.Link
                  className="text-xl text-white"
                  onClick={() => setModalShow(true)}
                >
                  Login
                </Nav.Link>
              </Nav>
            )}
            {isTokenAvailable && (
              <Nav className="profile-btn">
                <Nav.Link className="">
                  <Link className="booking-history-btn" to="booking-history">
                    Booking History
                  </Link>
                </Nav.Link>

                <Nav.Link className="logout-btn" onClick={logout}>
                  Logout
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse> 
          </Container>
          </Navbar>

*/
