import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from "formik";
import './Login.scss';
import AuthService from '../../services/AuthService';
import OtpModal from '../../components/Modal/OtpModal';

const Login = (props) => {
    const [otpModalOpen, setOtpModalOpen] = useState(false);  
    const [mobileNumber, setMobileNumber] = useState('');     
    

    
    const validate = values => {
        let errors = {};
        if (!values.phone) {
            errors.phone = 'Required';
        } else if (values.phone.toString().length !== 10) {
            errors.phone = 'Phone number should be 10 digits';
        }
        return errors;
    };

    const onSubmit = (values) => {
        console.log("Submitting phone number for OTP: ", values);

        let mobile = values.target.phone.value;

        // Request OTP from the backend
        AuthService.requestOtp(mobile)
            .then((response) => {
                console.log('OTP sent successfully', response);
                setMobileNumber(values.phone);  
                setOtpModalOpen(true); 
            })
            .catch((error) => {
                console.error('Error requesting OTP', error);
            });
    };

    
    const formik = useFormik({
        initialValues: {
            phone: "",  
        },
        onSubmit, 
        validate, 
        enableReinitialize: true,
    });

    return (
        <div>
            <Modal
                {...props}
                size="md"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Let's sign you in
                    </Modal.Title>
                    <p>For Exciting Discounts & Cashback</p>
                </Modal.Header>
                <Modal.Body className='w-100'>
                    <form onSubmit={formik.handleSubmit}>  {/* Use formik's handleSubmit */}
                        <div className='mt-3'>
                            <input 
                                type='number'
                                name='phone'
                                placeholder='Mobile Number'
                                className='form-control shadow-none'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}      
                                value={formik.values.phone}    
                            />
                            {/* Validation error display */}
                            {formik.touched.phone && formik.errors.phone ? (
                                <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                                    * {formik.errors.phone}
                                </div>
                            ) : null}
                        </div>
                        <div className='mt-3'>
                            <button type='submit' className='btn btn-primary'>Request OTP</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/* OTP Modal */}
            <OtpModal 
                isOpen={otpModalOpen}  
                onClose={() => setOtpModalOpen(false)}  
                mobileNumber={mobileNumber}  
                onSubmit={(otp) => {  
                    console.log('OTP entered:', otp);
                    AuthService.verifyOtp(mobileNumber, otp)
                        .then((response) => {
                            console.log('Logged in successfully', response);
                            setOtpModalOpen(false); 
                            props.onHide(); 
                            window.location.reload(); 
                        })
                        .catch((error) => {
                            console.log('Error verifying OTP', error);
                        });
                }}
                resendOtp={() => {  
                    AuthService.requestOtp(mobileNumber)
                        .then(() => {
                            console.log("OTP resent successfully");
                        })
                        .catch((error) => console.log("Error resending OTP", error));
                }}
            />
        </div>
    )
}

export default Login;
